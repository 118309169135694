 $('.js-triangle').each(function(){
        var $wrapper = $(this);
        var $triangle = $wrapper.find('.triangle').eq(0);
        var $triangleParent = $triangle.parent();
        var directionW = $wrapper.data('direction-w');
        var directionH = $wrapper.data('direction-h');
        var ratio = parseFloat($wrapper.data('ratio'));

        var widthThreashold = 1920;
        var minHeight = 100;
        var maxHeight = widthThreashold / ratio;
        
        var cssClassLookup = {"right": "border-right-width", "left": "border-left-width", "top": "border-top-width", "bottom": "border-bottom-width"};

        $triangle.addClass('triangle--'+directionH+directionW);

        var getTriangleHeight = function() {
            var height = $wrapper.width() / ratio;
            height = Math.max(height, minHeight);
            height = Math.min(height, maxHeight);
            return height;
        }

        var getPositioningStyle = function() {
            return directionH == 'bottom' ? 'top' : 'bottom';
        }

        var setupTriangle = function() {
            var sw = widthThreashold;
            if (matchMediaQuery("(min-width: "+widthThreashold+"px)")) {
                sw = $triangleParent.width();
            }
            $triangle.css(cssClassLookup[directionW], sw);

            var sh = getTriangleHeight();
            $wrapper.height(sh);
            $wrapper.css(getPositioningStyle(), -sh);
            $triangle.css(cssClassLookup[directionH], sh);
        }

        setupTriangle();
        $(window).on('resize', setupTriangle);
        $(window).on("load", setupTriangle);
 });
